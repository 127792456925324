<template>
  <tr>
    <td class="justify-center">
      <v-btn outline color="red" disabled small>
        <v-icon>delete</v-icon>
        <span> Apagar</span>
      </v-btn>
    </td>
    <td class="justify-left">
      <v-icon
        small
        v-if="props.item.reply_content.field.icon"
        :color="props.item.reply_content.field.color"
      >
        {{ props.item.reply_content.field.icon }}
      </v-icon>
      <strong v-if="props.item.reply_content && props.item.reply_content.field">
        {{ props.item.reply_content.field.label }}
      </strong>
    </td>
    <td class="justify-left">
      <strong style="font-size: 18px;">
        {{ props.item.content || 'Conteúdo não inserido ou removido' }}
      </strong>
    </td>
    <td class="justify-left">
      {{props.item.person.name}}
    </td>
    <td class="justify-left">
      <span v-if="props.item.business">
        <span v-if="props.item.business.info">
          {{ props.item.business.info.title }}
        </span>:
        <strong> #{{ props.item.business.id }} </strong>
      </span>
      <span v-else>- - -</span>
    </td>
    <td class="justify-left" style="font-size: 18px;">
      {{ props.item.created_at | moment('DD/MM/YY HH:mm') }}
    </td>
  </tr>
</template>
<script>
  export default {
    name: 'FormReplyContentHistory',
    props: {
      config: Object,
      props: Object
    }
  }
</script>
<style scoped>
  td {
    padding: 0 5px !important;
  }
  th {
    padding: 0 5px !important;
  }
  .persons__row-inactive {
    background: rgb(209, 209, 209);
  }
  .persons__status {
    border-width: 1px;
    border-style: solid;
    padding: 2px 5px;
  }
  .persons__status--active {
    color: #eceff1;
    background: rgb(4, 112, 0);
  }
  .persons__status--inactive {
    color: grey;
    background: #eceff1;
  }
</style>